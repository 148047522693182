<template>
  <div class="row">
    <div v-for="v in getFeaturedVehicles" :key="v.id" class="col-list-3">
      <div class="featured-car-list" @click="navigateCarDetail(v.id)" style="cursor: pointer;" >
        <div class="featured-car-img">
          <div v-if="v.advert.tag" class="ribbon-holder">
            <div class="ribbon ribbon-holder">{{ v.advert.tag }}</div>
              <img :src="getThumbImage(v)" class="img-fluid" :alt="v.advert.title" />
          </div>
          <img v-else :src="getThumbImage(v)" class="img-fluid" :alt="v.advert.title">
        </div>
        <div class="featured-car-content">
          <h6>{{ v.advert.title }}</h6>
          <div class="price_info">
            <p class="featured-price">£{{ thousandsSeparators(v.advert.price) }}</p>
            <div class="car-location">{{ v.advert.subtitle }}</div>
          </div>
          <ul>
            <li><i class="fa fa-calendar" aria-hidden="true"></i>{{ formatYear(v.vehicle.registrationDate) }}</li>
            <li><i class="fa fa-road" aria-hidden="true"></i>{{ thousandsSeparators(v.vehicle.mileage) }} miles</li>
            <li><i class="fa fa-paint-brush" aria-hidden="true"></i>{{ v.vehicle.colour }}</li>
            <li><i class="fa fa-tachometer" aria-hidden="true"></i>{{ formatEngine(v.vehicle.engine) }} L</li>
            <li><i class="fa fa-car" aria-hidden="true"></i>{{ v.vehicle.fuelType }}</li>
            <li><i class="fa fa-cogs" aria-hidden="true"></i>{{ v.vehicle.transmission }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'FeaturedCars',
  computed: {
    ...mapGetters('stock', ['getFeaturedVehicles'])
  },
  methods: {
    checkNewSymbol(dt) {
      // If last 7 days, return true
      return moment(dt).diff(moment(), 'days') < 7
    },
    formatYear(dt) {
      return moment(dt).format('YYYY')
    },
    formatEngine(size) {
      return (Math.round(size)/1000).toFixed(1)
    },
    getThumbImage(vehicle) {
      return vehicle.images?.length ? vehicle.images[0].url : 'https://firebasestorage.googleapis.com/v0/b/midlandautosales-c2dcf.appspot.com/o/ComingSoon.jpg?alt=media'
    },
    navigateCarDetail(id) {
      this.$router.push({
        name: 'Car Detail',
        params: { id: id }
      })
    }
  }
}
</script>

<style scoped>
.ribbon-holder {
  overflow: hidden;
  position: relative
}
.ribbon {
  position: absolute;
  background: #DE3732;
  color: #FFFFFF;
  transform: rotate(-45deg);
  text-align: center;
  top: 32px;
  left: -32px;
  width: 145px;
}
</style>
